import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";

import { Routes, ServiceInfo, TheViewProductCodes } from "../../../services/constants";
import { DigitalProduct } from "../../../components/digital-product/DigitalProduct";
import { Loader } from "../../../components/loader/Loader";
import Price from "../../../components/price/Price";
import { Section } from "../../../components/section/Section";
import { notUndefinedOrNull } from "../../../services/notUndefinedOrNull";
import { isFirstPeriodDiscounted } from "../../../services/isFirstPeriodDiscounted";
import { findBestServiceOffer } from "../../../services/findBestServiceOffer";
import {
  ServiceProductCode,
  useDigitalProductsViewQuery,
  PaymentEntityType,
  useDigitalProductsCreateTheViewForDagsCartMutation,
  useDigitalProductsCreateProductsForDagsCartMutation,
  ServiceOfferAvailability,
  ServiceSubscriptionStatus,
  useViewerQuery,
  UserXeraInfo,
} from "../../../graphql/schema";
import { showGraphqlValidationErrors } from "../../../services/showGraphqlValidationErrors";
import NotFound from "../../../components/not-found/NotFound";
import { ServiceIcon } from "../../../components/service-icon/ServiceIcon";
import { TheView, TheViewExclusiveOfferMembership } from "./the-view/TheView";
import { OfferSubmitMethod } from "../../../components/digital-product-exclusive-offer/offers/Offers";
import { useHandelPayment } from "../../../hooks/useHandlePayment";
import assertNever from "../../../services/assertNever";
import { useServicePeriodTranslation } from "../../../hooks/useServicePeriodTranslation";
import { hasAvailableOffers } from "../../../services/hasAvailableOffers";
import { getServicesOffers } from "../../../services/getServicesOffers";
import { PAYMENT_METHODS_ORDER } from "../../../components/payment-methods-list/PaymentMethodsList";
import { Badge } from "../../../components/badge/Badge";
import { Countdown } from "../../../components/countdown/Countdown";
import { DigitalProductBase } from "../../../components/digital-product-base/DigitalProductBase";
import { AsyncImg } from "../../../components/async-img/AsyncImg";
import { Button } from "../../../components/button/Button";
import { Color } from "../../../services/buttonLinkConstants";
import { Modal, ModalKind } from "../../../components/modal/Modal";
import { PanelWidth } from "../../../components/panel/Panel";

import styles from "./digital-products-view.module.scss";

export default function DigitalProductsView() {
  const [t] = useTranslation();
  const getPeriodName = useServicePeriodTranslation();
  const { push } = useHistory();
  const query = useDigitalProductsViewQuery({
    variables: { theViewProductCodes: TheViewProductCodes, paymentMethod: PAYMENT_METHODS_ORDER[0] },
  });
  const [paymentResId, setPaymentResId] = useState<string | null>(null);
  const [createTheViewForDagsCart] = useDigitalProductsCreateTheViewForDagsCartMutation();
  const [createProductsForDagsCart] = useDigitalProductsCreateProductsForDagsCartMutation();
  const handleTheViewForDagsPayment = useHandelPayment({
    onSuccess: () => push(`${Routes.CHECKOUT_RESULT}/CART_PURCHASE_OF_SERVICE_PRODUCTS/${paymentResId}`),
    onError: () => toast.error(t("Something went wrong")),
    onUnknownResponse: () => console.warn("payment status unknwon"),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: me } = useViewerQuery({ fetchPolicy: "network-only" });

  const services = [query.data?.forex, query.data?.forexPro, query.data?.membershipBasic].filter(notUndefinedOrNull);

  // SF Business Fee active subscription
  const activeSfFeeSubscription = query.data?.me.activeBusinessFeeSubscription;

  // check if SF Buseness Fee subscription is to expire in 14 or less days
  const isSfFeeExipring =
    new Date(activeSfFeeSubscription?.dateEnd || 0).getTime() <= daysToMilliseconds(14) + Date.now() &&
    new Date(activeSfFeeSubscription?.dateEnd || 0) > new Date();

  // check if SF Business fee subscription grace period has expired
  const isSfFeeInGracePeriod =
    new Date(activeSfFeeSubscription?.gracePeriodDateEnd || 0).getTime() <= daysToMilliseconds(30) + Date.now() &&
    new Date(activeSfFeeSubscription?.gracePeriodDateEnd || 0) > new Date();

  function getDetail(availability: keyof typeof ServiceOfferAvailability | keyof typeof ServiceSubscriptionStatus) {
    switch (availability) {
      case "UNAVAILABLE_ALREADY_SUBSCRIBED":
      case "UNAVAILABLE_FOR_UPGRADE":
      case "ACTIVE":
      case "PAYMENT_PENDING":
      case "PAYMENT_FAILED":
      case "DISCONTINUED":
        return t("Active membership");

      case "AVAILABLE_FOR_PURCHASE":
      case "AVAILABLE_FOR_UPGRADE":
        return t("Purchase membership");

      case "UNAVAILABLE_ALREADY_IN_CART":
        return t("In cart");

      case "UNAVAILABLE_FOR_COUNTRY":
        return t("Unavailable");

      case "EXPIRED":
      case "CANCELLED":
        return t("Discontinued");

      default:
        return assertNever(availability);
    }
  }

  async function handleTheViewOfferSubmit(paymentMethod: OfferSubmitMethod, ids: string[], totalPrice: string) {
    const dagOffersIds = (query.data?.me.theViewForDags?.offers ?? [])
      .filter((off) => ids.includes(off.id))
      .map((off) => off.dagOffer.id);

    try {
      const cartRes = await createTheViewForDagsCart({ variables: { dagOffersIds } });
      const paymentRes = await handleTheViewForDagsPayment({
        errorMessage: t("Something went wrong"),
        entityId: cartRes.data?.createTheViewForDagsCart.id,
        totalPrice,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode: "DAG",
        },
        selectedPaymentSource: {
          id: null,
          paymentMethod,
        },
      });

      setPaymentResId(paymentRes.data?.payments.create.id ?? null);
    } catch (e) {
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  }

  async function handleSpecialOfferSubmit(paymentMethod: OfferSubmitMethod, ids: string[], totalPrice: string) {
    try {
      const cartRes = await createProductsForDagsCart({ variables: { offerIds: ids } });
      const paymentRes = await handleTheViewForDagsPayment({
        errorMessage: t("Something went wrong"),
        entityId: cartRes.data?.createProductsForDagsCart.id,
        totalPrice,
        paymentVariables: {
          entityType: PaymentEntityType.PURCHASE_OF_SERVICE_PRODUCTS,
          currencyCode: "DAG",
        },
        selectedPaymentSource: {
          id: null,
          paymentMethod,
        },
      });

      setPaymentResId(paymentRes.data?.payments.create.id ?? null);
    } catch (e) {
      if (!showGraphqlValidationErrors(t, e) && e instanceof Error) {
        toast.error(e.message, { autoClose: false });
      }
    }
  }

  if (query.error) {
    return <NotFound>{t("Something went wrong. Please reload the page or contact support")}</NotFound>;
  }

  const theViewSpecialOfferMemberships: TheViewExclusiveOfferMembership[] = (
    query.data?.me.theViewForDags?.offers ?? []
  ).map((offer) => ({
    id: offer.id,
    labelText: offer.eurOffer.labelText,
    datePurchased: offer.eurOfferPurchaseDate,
    eurPrice: offer.eurOffer.fullPrice,
    dagPrice: offer.dagOffer.fullPrice,
  }));

  const hasTheViewOffers = hasAvailableOffers(getServicesOffers(query.data?.theView ?? []));
  const hasTheViewOffersOrAccess =
    hasAvailableOffers(getServicesOffers(query.data?.theView ?? [])) ||
    theViewSpecialOfferMemberships.length > 0 ||
    query.data?.me.theiaAccess;

  const availablePaymentMethods = query?.data?.me.paymentMethods.filter((m) => m.enabled).map((m) => m.method) ?? [];
  const dagsBalance = query?.data?.viewer?.coinAccountBalance ?? 0;

  const xeraUser = (user: UserXeraInfo) => (
    <>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>
        {user.username}
        <span style={{ fontSize: "10px", color: "#666", fontWeight: 600 }}>{user.isIncomeCentre ? " (IC)" : null}</span>
      </div>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>{user.xeraUsername}</div>
      <div style={{ fontSize: "12px", padding: "5px 0" }}>{user.xeraEmail}</div>
    </>
  );

  return (
    <>
      <Section gutter={100} center withSpace className={styles.wrap}>
        <Loader visibleOnFirstLoad={query} />

        {me?.me?.xera && me?.me?.xera?.length > 0 && (
          <DigitalProductBase
            title={t("XERA Login Details")}
            logo={<AsyncImg className={styles.view} src="/images/XERA.svg" />}
            publicUrl={undefined}
            price={undefined}
            exclusiveOffer={undefined}
            onExclusiveOfferClick={() => null}
          >
            <Button
              className={styles["access-product-link"]}
              borderRadius="SMALL"
              color={Color.DARK_BLUE}
              fontSize={18}
              onClick={() => setIsModalOpen(true)}
            >
              <span className={styles["link-label"]}>{t("Show Details")}</span>
            </Button>
            <Modal
              isOpen={isModalOpen}
              close={() => setIsModalOpen(false)}
              kind={ModalKind.PRIMARY}
              panelProps={{
                imagePath: "/images/XERA.svg",
                width: PanelWidth.SMALL,
                title: t("XERA Login Details"),
                caption: t("Here can you see your XERA login details"),
                label: t("XERA"),
                titleClassName: styles["modal-title"],
                captionClassName: styles["modal-caption"],
              }}
            >
              <div style={{ width: "100%", textAlign: "center", alignItems: "center", marginBottom: "20px" }}>
                {<AsyncImg className={styles.view} style={{ width: "100px" }} src="/images/XERA.svg" />}
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridGap: "0",
                }}
              >
                <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
                  SF user
                </div>
                <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
                  XERA user
                </div>
                <div style={{ backgroundColor: "#fafafa", fontWeight: 600, fontSize: "12px", padding: "5px 0" }}>
                  XERA email
                </div>
                {me.me.xera.map((user) => xeraUser(user))}
              </div>
              <div
                style={{
                  maxWidth: "700px",
                  border: "1px solid #ddd",
                  backgroundColor: "#fafafa",
                  padding: "7px 15px",
                  marginTop: "15px",
                }}
              >
                <p style={{ marginTop: "15px", marginBottom: "0", fontWeight: 600 }}>
                  In order to gain access to your accounts in XERA:
                </p>
                <ol>
                  <li>
                    Please go to the single sign-on platform{" "}
                    <a
                      style={{ color: "#ffb100" }}
                      href="https://2access.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      2access.io
                    </a>
                  </li>
                  <li>Click on Forgot Password</li>
                  <li>Enter the e-mail address you have used to register your account in SF</li>
                  <li>Open your mailbox and click on the link you received</li>
                  <li>Set up a new password and go back to front page</li>
                  <li>Log in using your username (seen in the e-mail you received) or e-mail address</li>
                  <li>Choose either a personal or company account</li>
                  <li>
                    Set up your 2-FA (2 factor authentication) with Google Authenticator (download from app stores) for
                    account security
                  </li>
                  <li>Choose the platforms to enroll to (recommended to choose all) and you’re done!</li>
                </ol>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  XERA operates with unique user access, disallowing special characters in usernames and duplicate
                  e-mails. In case you had multiple accounts/income centers with the same e-mail or used special
                  characters in your username, then your username(s) have been modified, and/or numbers have been added
                  to duplicate e-mails for differentiation.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  For example, if you previously had a main account and two income centers (or family members)
                  registered with the same e-mail address, for example with yourusername@gmail.com, then your e-mails
                  for those accounts will be like this: Main account - yourusername@gmail.com IC1 or family member 1: -
                  yourusername+1@gmail.com IC2 or family member 2: - yourusername+2@gmail.com
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  Each income center is a separate account in XERA and you should follow steps 1-9 for each one of those
                  accounts, using the new adjusted e-mail addresses (XERA e-mail, as shown above). All the +1, +2 etc
                  e-mails are forwarded to your main e-mail (yourusername@gmail.com) so you get all your logins in one
                  and the same mailbox.
                </p>
                <p style={{ marginTop: "10px", marginBottom: "10px" }}>
                  In case you face difficulties/issues in logging in, please submit a support ticket (
                  <a
                    style={{ color: "#ffb100" }}
                    href="https://support.2access.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.2access.io/
                  </a>
                  ) and the support team will assist you
                </p>
              </div>
            </Modal>
          </DigitalProductBase>
        )}

        {activeSfFeeSubscription && isSfFeeExipring && (
          <Badge
            className={classNames(styles.note, styles["note--expiration"])}
            label={
              <h4 className={styles["expiry-notification-title"]}>
                {t("Your subscription for the {{product}} will renew in", {
                  product: ServiceInfo[activeSfFeeSubscription.productCodes[0]].title,
                })}
              </h4>
            }
            badgeGutter={"LARGE"}
            wrap
          >
            <Countdown
              targetDate={
                activeSfFeeSubscription && activeSfFeeSubscription.dateEnd
                  ? activeSfFeeSubscription?.dateEnd?.toString()
                  : ""
              }
              onCountDownEnd={query.refetch}
            />
          </Badge>
        )}

        {activeSfFeeSubscription && isSfFeeInGracePeriod && (
          <Badge
            className={classNames(styles.note, styles["note--grace-period"])}
            label={
              <h4 className={styles["expiry-notification-title"]}>
                {t("Your subscription grace period for the {{product}} will expire in", {
                  product: ServiceInfo[activeSfFeeSubscription.productCodes[0]].title,
                })}
                :
              </h4>
            }
            badgeGutter={"LARGE"}
            wrap
          >
            <Countdown
              targetDate={activeSfFeeSubscription?.gracePeriodDateEnd.toString()}
              onCountDownEnd={query.refetch}
            />
          </Badge>
        )}

        {hasTheViewOffersOrAccess && (
          <TheView
            publicUrl={hasTheViewOffers ? Routes.THE_VIEW_LANDING : undefined}
            className={styles.view}
            packages={query.data?.theView}
            detail={hasTheViewOffers ? t("Purchase a package") : undefined}
            key="theia-service"
            hasAccess={query.data?.me.theiaAccess ?? null}
            exclusiveOffer={
              query.data?.me.theViewForDags && theViewSpecialOfferMemberships.length > 0
                ? {
                    dagsBalance,
                    dateStart: new Date(query.data.me.theViewForDags.dateStart),
                    dateEnd: new Date(query.data.me.theViewForDags.dateEnd),
                    expiresInMS: Number(query.data.me.theViewForDags.expiresInSeconds) * 1000,
                    memberships: theViewSpecialOfferMemberships,
                    onSubmit: handleTheViewOfferSubmit,
                    availablePaymentMethods,
                  }
                : undefined
            }
          />
        )}

        {services.map((service) => {
          const offer = findBestServiceOffer(service.offers);
          const offerCampaign = getExclusiveOfferCampaignInfo(service.code, query.data?.me.productsForDags ?? []);
          const exclusiveOffer = offerCampaign?.offers[0];
          const activeSubscription = query.data?.me.serviceSubscriptions.find((s) =>
            s.productCodes.includes(service.code),
          );

          if (!offer && !activeSubscription) {
            return null;
          }

          return (
            <DigitalProduct
              key={service.code}
              logo={
                <ServiceIcon
                  productCode={service.code}
                  forexLogoClassName={styles.forex}
                  businessFeeLogoClassName={styles["business-fee"]}
                  bankLogoClassName={styles.bank}
                />
              }
              title={service.name}
              detail={getDetail((offer?.availability || activeSubscription?.status) ?? "UNAVAILABLE_FOR_COUNTRY")}
              price={
                offer && (
                  <Price
                    price={offer.fullPrice}
                    discountPrice={offer.discountedPrice}
                    currency={offer.currency.code}
                    isDigitalProduct
                    digitalProductPurchaseType={offer.period.code}
                  />
                )
              }
              priceNext={
                offer && isFirstPeriodDiscounted(offer)
                  ? t("*{{price}}{{currencySign}} / {{period}} from the next period on", {
                      price: offer.fullPriceNext,
                      currencySign: offer.currency.sign,
                      period: getPeriodName(offer.period.code, "SHORT"),
                    })
                  : undefined
              }
              productCode={service.code}
              periodCode={offer?.period.code || activeSubscription?.periodCode}
              availability={getOfferAvailability(offer, !!activeSubscription)}
              activeSubscriptionId={offer?.upgradableSubscriptions[0]?.id ?? activeSubscription?.id}
              producerName={renderProducerName(service.name)}
              externalUrl={getAccessUrl(service.code)}
              publicUrl={
                offer ||
                [ServiceProductCode.BUSINESS_FEE_BASIC, ServiceProductCode.BUSINESS_FEE_PRO].includes(service.code)
                  ? ServiceInfo[service.code]?.publicUrl || undefined
                  : undefined
              }
              exclusiveOfferInfo={
                offerCampaign &&
                exclusiveOffer && {
                  dateStart: new Date(offerCampaign.dateStart),
                  dateEnd: new Date(offerCampaign.dateEnd),
                  expiresInMS: offerCampaign.expiresInSeconds * 1000,
                  dagsBalance,
                  id: exclusiveOffer.id,
                  periodCode: exclusiveOffer.period.code,
                  priceInDag: exclusiveOffer.fullPrice,
                  priceInEur: exclusiveOffer.fullPriceEur,
                  availablePaymentMethods,
                  onSubmit: handleSpecialOfferSubmit,
                }
              }
              gracePeriod={
                activeSubscription &&
                [ServiceSubscriptionStatus.PAYMENT_FAILED, ServiceSubscriptionStatus.PAYMENT_PENDING].includes(
                  activeSubscription.status,
                )
                  ? {
                      dateEnd: new Date(activeSubscription.gracePeriodDateEnd),
                      expiresInMS: activeSubscription.gracePeriodExpiresInSeconds * 1000,
                      subscription: {
                        ...activeSubscription,
                        billingCurrency: query.data?.me.currency ?? activeSubscription.billingCurrency,
                      },
                    }
                  : undefined
              }
              onGracePeriodRenewalSuccess={() => {
                query.refetch();
              }}
              isLoading={query.loading}
              onPaymentMethodChange={(paymentMethod) => query.refetch({ paymentMethod })}
              serviceCode={service.code}
              comingSoon={service.code === ServiceProductCode.MEMBERSHIP_PRO}
              isPaymentInfoVisible={service.code !== ServiceProductCode.MEMBERSHIP_PRO}
            />
          );
        })}
      </Section>
    </>
  );

  // get external bank product url
  function getAccessUrl(code: ServiceProductCode) {
    // bank connect url
    const bankFullUrl = query.data?.me.codebreakerAccess?.bankFullUrl;
    // bank beyond url
    const bankBasicUrl = query.data?.me.codebreakerAccess?.bankBasicUrl;

    // forex url
    if (code === ServiceProductCode.FOREX || code === ServiceProductCode.FOREX_PRO) {
      return query.data?.me.forexInsidersAccess ?? undefined;
    }

    // return bank connect url
    if (code === ServiceProductCode.VIEW_BASIC && bankBasicUrl && !bankFullUrl) {
      return bankBasicUrl;
    }

    // return bank beyond url
    if ((code === ServiceProductCode.BANK_BASIC || code === ServiceProductCode.BANK_FULL) && bankFullUrl) {
      return bankFullUrl;
    }

    // membership url
    if (code === ServiceProductCode.MEMBERSHIP_BASIC || code === ServiceProductCode.MEMBERSHIP_PRO) {
      return query.data?.me.membershipAccess ?? undefined;
    }

    // no access link for sf fee
    if (code === ServiceProductCode.BUSINESS_FEE_BASIC || code === ServiceProductCode.BUSINESS_FEE_PRO) {
      return false;
    }

    return undefined;
  }
}

// render digital product producer name
function renderProducerName(producerName: string) {
  switch (producerName) {
    case "Forex Insiders":
    case "Forex Insiders Pro":
      return "Forex Insiders BV";
    case "B.A.N.K. Connect":
    case "B.A.N.K. Beyond":
      return " Codebreaker Technologies Inc.";
  }
}

function getExclusiveOfferCampaignInfo<T extends { product: { code: ServiceProductCode } }>(
  code: ServiceProductCode,
  productsForDags: T[],
) {
  return productsForDags.find((p) => p.product.code === code);
}

function getOfferAvailability(
  offer?: { availability: ServiceOfferAvailability },
  hasActiveSubscription?: boolean,
): ServiceOfferAvailability | undefined {
  if (offer?.availability) {
    return offer.availability;
  }

  if (hasActiveSubscription) {
    return ServiceOfferAvailability.UNAVAILABLE_ALREADY_SUBSCRIBED;
  }

  return undefined;
}

function daysToMilliseconds(days: number) {
  return days * 24 * 60 * 60 * 1000;
}
